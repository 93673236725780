export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Kegiatan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Sub Kegiatan',
      field: 'rincian_output',
      filterOptions: {
        enabled: true,
        placeholder: 'Sub Kegiatan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      type: 'number',
      field: 'total_anggaran',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Perubahan Terakhir',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Status',
      field: 'status',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Nama Pengadaan',
      field: 'nama_pengadaan',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Pengadaan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Kriteria Belanja',
      field: 'jenis_pengadaan',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Total Anggaran',
      type: 'number',
      field: 'total_anggaran',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Nama Kegiatan',
      field: 'nama_kegiatan',
      filterOptions: {
        enabled: true,
        placeholder: 'Nama Kegiatan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Sub Kegiatan',
      field: 'rincian_output',
      filterOptions: {
        enabled: true,
        placeholder: 'Sub Kegiatan',
      },
    },
    {
      thClass: 'text-center',
      label: 'Perubahan Terakhir',
      field: 'last_update',
    },
    {
      thClass: 'text-center',
      label: 'Status',
      field: 'status',
      sortable: false,
    },
    {
      thClass: 'text-center',
      label: 'Action',
      field: 'action',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
