<template>
  <b-card v-if="profileData">
    <!-- about -->
    <h3 class="text-center mb-2">
      {{ profileData.instansi_name }}
    </h3>
    <b-row>
      <b-col
        md="12"
        class="mb-2"
      >
        <h5 class="text-capitalize mr-2">
          Tahun Anggaran
        </h5>
        <v-select
          v-model="year"
          required
          :clearable="false"
          disabled
          name="year_budget"
          :options="year_budget"
          placeholder="Tahun Anggaran"
          class="flex-fill"
          @input="changeYear"
        />
      </b-col>

      <b-col md="6">
        <h5 class="text-capitalize mb-75">
          Total Anggaran
        </h5>
        <b-card-text>
          {{ (profileData.total_anggaran) ? Number(profileData.total_anggaran).toLocaleString() : 0 }}
        </b-card-text>
      </b-col>

      <b-col md="6">
        <h5 class="text-capitalize mb-75">
          Total Kegiatan
        </h5>
        <b-card-text>
          {{ (profileData.total_kegiatan) ? profileData.total_kegiatan : 0 }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    masterId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    const date1 = 2024

    return {
      userData: getUserData(),
      profileData: {
        instansi_name: '',
      },
      year: Number(localStorage.getItem('tahunAnggaran')),
      year_budget: [date1, date1 + 1],
    }
  },
  mounted() {
    this.getStat()
  },
  methods: {
    changeYear() {
      localStorage.setItem('tahunAnggaran', this.year.toString())
      window.location.reload()
      this.getStat()
    },
    getStat() {
      if (this.masterId !== 0) {
        this.$http.get('/analytic/instansi', {
          params: {
            token: localStorage.getItem('userToken'),
            instansi_id: this.masterId,
            tahun_anggaran: localStorage.getItem('tahunAnggaran'),
          },
        })
          .then(res => {
            if (res.data.status === 'success') {
              this.profileData = res.data.data
              this.profileData.instansi_name = this.profileData.name
            }
          })
      } else {
        this.profileData.instansi_name = this.userData.instansi_name
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
