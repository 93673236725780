export default [{
  name: 'pengajuan-master-program',
  path: '/pengajuan/master/program',
  component: () => import('@/views/pengajuan/master/MasterProgram.vue'),
  meta: {
    pageTitle: 'Master Program / Proses Bisnis',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Program',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-data-informasi',
  path: '/pengajuan/master/data-informasi',
  component: () => import('@/views/pengajuan/master/MasterDataInformasi.vue'),
  meta: {
    pageTitle: 'Master Data dan Informasi',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Data dan Informasi',
        active: true,
      },
    ],
  },
},
{
  name: 'pengajuan-master-layanan',
  path: '/pengajuan/master/layanan',
  component: () => import('@/views/pengajuan/master/MasterLayanan.vue'),
  meta: {
    pageTitle: 'Master Layanan',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Layanan',
        active: true,
      },
    ],
  },
},
// {
//   name: 'pengajuan-master-aplikasi',
//   path: '/pengajuan/master/aplikasi',
//   component: () => import('@/views/pengajuan/master/MasterLayanan.vue'),
//   meta: {
//     pageTitle: 'Master Aplikasi',
//     breadcrumb: [
//       {
//         text: 'Pengajuan',
//       },
//       {
//         text: 'Master Data',
//       },
//       {
//         text: 'Aplikasi',
//         active: true,
//       },
//     ],
//   },
// },
// {
//   name: 'pengajuan-master-infrastruktur',
//   path: '/pengajuan/master/infrastruktur',
//   component: () => import('@/views/pengajuan/master/MasterLayanan.vue'),
//   meta: {
//     pageTitle: 'Master Infrastruktur',
//     breadcrumb: [
//       {
//         text: 'Pengajuan',
//       },
//       {
//         text: 'Master Data',
//       },
//       {
//         text: 'Infrastruktur',
//         active: true,
//       },
//     ],
//   },
// },
{
  name: 'pengajuan-master-kontak',
  path: '/pengajuan/master/kontak',
  component: () => import('@/views/pengajuan/master/MasterContact.vue'),
  meta: {
    navActiveLink: 'pengajuan',
    pageTitle: 'Master Penanggung Jawab',
    breadcrumb: [
      {
        text: 'Pengajuan',
      },
      {
        text: 'Master Data',
      },
      {
        text: 'Penanggung Jawab',
        active: true,
      },
    ],
  },
}]
